<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 103px; width:411px">
        <field name="codigo" widget="char" searchable label="Código" help="Código" placeholder="Cod" width="50px"
          :readonly="mode == 'edit'" style="top:9px; left:30px;" />
        <field name="nombre" widget="char" searchable label="Nombre" help="Descripción" placeholder="Descripción"
          width="270px" style="top:9px; left:95px;" />
        <field :readonly="mode == 'edit'" name="model" label="Modelo" searchable :options="{
          'Factura Proveedor': 'factura_proveedor',
          'Factura Cliente': 'factura_cliente',
          'Pedido Cliente': 'pedido_cliente',
          Reparación: 'reparacion',
          Cliente: 'cliente'
        }" widget="select" width="270px" style="top:45px; left:95px;" />
        <button @click="openEditor" :disabled="!itemId" title="Diseñar"
          style="position:absolute;left:29px;top:52px;width:50px;">
          <fa-icon :icon="['fas', 'paint-brush']" />
        </button>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    return {
      title: "Plantillas de informes",
      dbAdapter: "plantilla_informe",
      primary: "codigo"
    };
  },
  methods: {
    openEditor: function () {
      let self = this;
      let obj = {
        id: self.itemId,
        token: window.session.token
      }
      let params = window.$.param(obj);
      //window.open(self.app.urlGeneradorInformes + 'editor.php?' + params)
      window.open(
        self.app.urlGeneradorInformesDocumentoEditor + self.itemId + "/" + window.session.token + "/",
        "_blank"
      );
    }
  }
};
</script>
